import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css'
import { AgentChanges } from '../../models/AgentSkills/AgentChanges';

export interface IProps {
  agent: AgentChanges,
  handleChange: (name: string, checked: boolean) => void
}

export default class AgentKPIComponent extends React.Component<IProps> {

  _agentKpi = [
    { id: 'best_booker', name: 'Best Booker' },
    { id: 'cancelation_ratio', name: 'Cancelation ratio' },
    { id: 'best_sel', name: 'Best Seller' },
    { id: 'adl', name: 'ADL $200' },
    { id: 'professional', name: 'Group A (Professional)' },
    { id: 'amateur', name: 'Group B (Amateur)' },
    { id: 'beginner', name: 'Group C (Beginner)' },
    { id: 'group_d', name: 'Group D (Managers)' },
    { id: 'group_e', name: 'Group E' },
    { id: 'group_f', name: 'Group F' },
  ];



  getAgentProp(name: string): boolean {
    let val: boolean

    switch (name) {
      case 'best_booker':
        val = this.props.agent.BestBooker
        break
      case 'cancelation_ratio':
        val = this.props.agent.CancelationRatio
        break
      case 'best_sel':
        val = this.props.agent.BestSel
        break
      case 'adl':
        val = this.props.agent.Adl
        break
      case 'professional':
        val = this.props.agent.Professional
        break
      case 'amateur':
        val = this.props.agent.Amateur
        break
      case 'beginner':
        val = this.props.agent.Beginner
        break
      case 'group_d':
        val = this.props.agent.GroupD
        break
      case 'group_e':
        val = this.props.agent.GroupE
        break
      case 'group_f':
        val = this.props.agent.GroupF
        break
      default:
        val = false;
        break
    }
    return val
  }

  changeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    let id = event.target.value
    let checked = event.target.checked
    this.props.handleChange(id, checked)

    if (checked) {
      const groups = ['professional', 'amateur', 'beginner', 'group_d', 'group_e', 'group_f'];
      if (groups.includes(id)) {
        groups.forEach(group => {
          if (group !== id) {
            this.props.handleChange(group, false);
          }
        })
      }
    }
  }


  render() {
    return (<div>
      <div className={mainStyles.ComponentHeader}><span >autocalculated KPI on-demand</span></div>
      <div className={mainStyles.ComponentBody} >
        {this._agentKpi.map((kpi, index) => {
          return (

            <label className={`${styles.SkillsCheckbox} ${styles.Underlined}`} key={kpi.id} >
              <input type="checkbox" value={kpi.id} checked={this.getAgentProp(kpi.id)} onChange={this.changeCheckbox.bind(this)} />
              <span className={`${(this.getAgentProp(kpi.id) ? styles.CheckLabelChecked : styles.CheckLabel)}`}>{kpi.name}</span>
            </label>

          )
        })}
      </div>
    </div>
    )
  }
}
