import { GUID } from "../Guid"
import { IFilter } from "./Filter"
import { IRoutingStep, RoutingStep } from "./RoutingStep"
import { ITrigger, Trigger } from "./Trigger"

export interface IWorkflow {
    sid: string
    friendly_name: string
    task_reservation_timeout: number
    default_queue_sid: string
    triggers: ITrigger[]
    filters: IFilter[]
}

export class Workflow {
    private sid: string = ''
    private friendly_name: string = ''
    private task_reservation_timeout: number = 30
    private default_queue_sid: string = ''
    private triggers: Trigger[] = []
    private filters: IFilter[] = []
    constructor(o: IWorkflow | undefined) {
        if (o !== undefined) {
            this.sid = o.sid
            this.friendly_name = o.friendly_name
            this.task_reservation_timeout = o.task_reservation_timeout || 30
            
            this.filters = o.filters.map((filter: IFilter): IFilter => { 
                filter.routing_steps = filter.routing_steps.map((step)=>{return new RoutingStep(step as unknown as IRoutingStep) })
                filter.guid = GUID.New()
                return filter 
            })
            this.default_queue_sid = o.default_queue_sid
            this.triggers = o.triggers.map((trigger: ITrigger): Trigger => { return new Trigger(trigger) })
        }
    }

    public get Sid(): string {
        return this.sid
    }

    public get FriendlyName(): string {
        return this.friendly_name
    }

    public get TaskReservationTimeout(): number {
        return this.task_reservation_timeout
    }

    public get Filters(): IFilter[] {
        return this.filters
    }

    public get DefaultQueueSid(): string {
        return this.default_queue_sid
    }

    public get Triggers(): Trigger[] {
        return this.triggers
    }

    public set FriendlyName(v: string) {
        this.friendly_name = v;
    }

    public set TaskReservationTimeout(v: number) {
        this.task_reservation_timeout = v;
    }

    public set DefaultQueueSid(v: string) {
        this.default_queue_sid = v;
    }

    public set Filters(v: IFilter[]) {
        this.filters = v;
    }

    public set Triggers(v: Trigger[]) {
        this.triggers = v;
    }

    public AddFilter(filter: IFilter) {
        this.filters.push(filter)
    }

    public RemoveFilter(filter: IFilter) {
        this.filters.forEach((element, index) => {
            if (element.guid === filter.guid) {
                this.filters.splice(index, 1)
                return 0;
            }
        });
    }

    public SetFilter(filter: IFilter) {
        this.filters.forEach((element, index) => {
            if (element.guid === filter.guid) {
                this.filters[index] = filter
                return 0;
            }
        });
    }

}