import CallSplitIcon from "@material-ui/icons/CallSplit";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";
import FolderOpen from "@material-ui/icons/FolderOpen";
import Folder from "@material-ui/icons/Folder";
import PersonIcon from "@material-ui/icons/Person";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { Filter } from "@material-ui/icons";

export interface IProps {
  open: boolean;
  toggleOpen: () => void;
  minimized: boolean;
}
export default class SidebarComponent extends React.Component<IProps> {
  render() {
    return (
      <div
        className={`${styles.sidebar} ${
          !this.props.open ? styles.sidebarClosed : ""
        } ${this.props.minimized ? styles.minimized : ""} `}
      >
        <div
          className={styles.sidebarTogglerItem}
          onClick={this.props.toggleOpen}
        >
          <MenuIcon className={styles.sidebarIcon} />
        </div>
        <Link to="/dashboard" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <Filter className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Dashboard</div>
          </div>
        </Link>
        <Link to="/queues" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <CallSplitIcon className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Queues</div>
          </div>
        </Link>
        <Link to="/workflows" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <DeviceHubIcon className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Workflows</div>
          </div>
        </Link>
        <Link to="/agents" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <PersonIcon className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Agents</div>
          </div>
        </Link>
        <Link to="/teams" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <PeopleIcon className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Teams</div>
          </div>
        </Link>
        <Link to="/location-brands-settings" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <BrandingWatermarkIcon className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Location Brands</div>
          </div>
        </Link>
        <Link to="/block-voip" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <Filter className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Block Voip</div>
          </div>
        </Link>
        <Link to="/spam-filter" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <Filter className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Spam Filter</div>
          </div>
        </Link>
        <Link to="/whitelist" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <FolderOpen className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Whitelist</div>
          </div>
        </Link>
        <Link to="/agents-score" className={styles.sidebarItemLink}>
          <div className={styles.sidebarItem}>
            <FolderOpen className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>Agents Score</div>
          </div>
        </Link>
        {/*<Link to="/blacklist" className={styles.sidebarItemLink} >
          <div className={styles.sidebarItem}>
            <Folder className={styles.sidebarIcon} />
            <div className={styles.sidebarItemText}>
              Blacklist
            </div>
          </div>
        </Link>*/}
      </div>
    );
  }
}
