import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Phone , History , Action } from "../../models/Whitelist/Phone";
import ActionsList from "./History";
import { WhiteListActions } from "../../actions/WhiteListActions";
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css'
import { Backdrop, Box, CircularProgress, Container, Grid, Snackbar } from "@material-ui/core";
import { BlueInverseBtn } from "../Styled/StyledButtons";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import styles from '../AgentSkillsManagement/styles.module.css'

const PhoneHistory = () => {
    let { phoneNumber } = useParams<{ phoneNumber: string }>();
    const [phone, setPhone] = useState<Phone | null>(null);
    const [backdrop, setBackdrop] = useState(true)
    const [errorAlert, setErrorAlert] = useState(false)
    const [errors, setErrors] = useState('')

    

    const loadPhone = () => {
        WhiteListActions.getPhone(phoneNumber).then((data: any) => {
            setBackdrop(false)
            setPhone(data)
        }).catch((error) => {
            setBackdrop(false)
            setErrors(error.message)
            console.log(error)
        })
    };

    useEffect(() => {
        loadPhone()
    }, []);


    const tel: string = phone?.phone || '';
    const comm: string = phone?.comment || '';

    return (
        <>
            <Container className={mainStyles.mainContainer} maxWidth={false}>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                        <Grid className={mainStyles.headInfo} container spacing={3}>
                            <Grid className={mainStyles.tableT} item xs={4}>
                                {tel}
                            </Grid>
                            <Grid className={mainStyles.tableT} item xs={4}>
                                {comm}
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid className={mainStyles.tableTitle} item xs={3}>
                                Event Datetime 
                            </Grid>
                            <Grid className={mainStyles.tableTitle} item xs={3}>
                                Action  
                            </Grid>
                            <Grid className={mainStyles.tableTitle} item xs={3}>
                                Comment 
                            </Grid>
                            <Grid className={mainStyles.tableTitle} item xs={3}>
                                Created by
                            </Grid>
                            <ActionsList history={phone?.history || []}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        {/* 
                        <Grid item xs={12} md={3}>
                            {phone !== null && phone.deleted_at !== null ?
                                <BlueInverseBtn variant="contained" fullWidth={true} href={'/queue-create'}><Add /> Restore</BlueInverseBtn>
                                :
                                ''
                            }
                        </Grid>
                        */}
                    </Grid>
                </Grid>
            </Container>
            <Snackbar open={errorAlert} autoHideDuration={5000} onClose={() => setErrorAlert(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Alert onClose={() => setErrorAlert(false)} severity="error" variant="filled">
                    Error<br />
                    <div className={styles.AlertError}>{errors}</div>
                </Alert>
            </Snackbar>
            <Backdrop className={styles.Backdrop} open={backdrop}>
                <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
                <p className={styles.BackdropProgress}>Loading...</p>
            </Backdrop>
        </>
    );
};

export default PhoneHistory