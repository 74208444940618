import Api from "./../config/api";
import { Agent, IAgent } from "../models/AgentSkills/Agent";
import { AgentChanges } from "../models/AgentSkills/AgentChanges";
import { CustomSkill, ICustomSkill } from "../models/AgentSkills/CustomSkill";
import { ITaskChannel, TaskChannel } from "../models/AgentSkills/TaskChannel";
import { TaskChannelChanges } from "../models/AgentSkills/TaskChannelsChanges";
import { IError } from "../models/IError";
import DeleteSkillApiError, {
  IDeleteSkillApiError,
} from "../models/ApiErrors/DeleteSkillApiError";
import { IAgents } from "../models/AgentSkills/Agents";
import history from "./../components/history";
import { AgentCustomKPI } from "../models/AgentSkills/AgentCustomKPI";

interface IAxiosAgent {
  data: IAgent;
}
export interface IAxiosAgents {
  data: IAgents;
}
interface IAxiosSkills {
  data: ICustomSkill[];
}
interface IAxiosError {
  response: { data: IError; status: number };
}
interface IAxiosDeleteSkillError {
  response: { data: IDeleteSkillApiError };
}
interface IAxiosSkill {
  data: ICustomSkill;
}

interface IAxiosAgentChannel {
  data: ITaskChannel;
}
interface IAxiosAgentChannels {
  data: ITaskChannel[];
}
export class AgentSkillsActions {
  static getSkills(): Promise<CustomSkill[]> {
    return Api.get("/skills").then((res: IAxiosSkills): CustomSkill[] => {
      return res.data.map((d: ICustomSkill): CustomSkill => {
        return new CustomSkill(d);
      });
    });
  }

  static storeSkill(skill: CustomSkill): Promise<CustomSkill | IError> {
    return Api.post("/skills", skill)
      .then((res: IAxiosSkill): CustomSkill => {
        return new CustomSkill(res.data);
      })
      .catch((res: IAxiosError): IError => {
        console.log("Error", res.response.data.full_error);
        return res.response.data;
      });
  }

  static deleteSkill(id: number): Promise<CustomSkill | DeleteSkillApiError> {
    return Api.delete(`/skills/${id}`)
      .then((res: IAxiosSkill): CustomSkill => {
        return new CustomSkill(res.data);
      })
      .catch((res: IAxiosDeleteSkillError): DeleteSkillApiError => {
        return new DeleteSkillApiError(res.response.data);
      });
  }

  static getAgent(id: number): Promise<Agent | IError> {
    return Api.get("/agents/" + id)
      .then((res: IAxiosAgent): Agent => {
        return new Agent(res.data);
      })
      .catch((res: IAxiosError): IError => {
        console.log("Error", res.response.data.full_error);
        if (res.response.status === 404) {
          history.replace("/404");
        }
        return res.response.data;
      });
  }

  static deleteAgent(id: number): Promise<number | IError> {
    return Api.delete("/agents/" + id)
      .then((res: IAxiosAgent): number => {
        return res.data.id;
      })
      .catch((res: IAxiosError): IError => {
        console.log("Error", res.response.data.full_error);
        return res.response.data;
      });
  }
  static updateAgent(id: number, agent: AgentChanges): Promise<Agent | IError> {
    return Api.put("/agents/" + id, agent)
      .then((res: IAxiosAgent): Agent => {
        return new Agent(res.data);
      })
      .catch((res: IAxiosError): IError => {
        console.log("Error", res.response.data.full_error);
        return res.response.data;
      });
  }
  static accessAgent(
    id: number,
    twilio_access: boolean
  ): Promise<Agent | IError> {
    return Api.put("/agents/" + id + "/access", {
      twilio_access: twilio_access,
    })
      .then((res: IAxiosAgent): Agent => {
        return new Agent(res.data);
      })
      .catch((res: IAxiosError): IError => {
        console.log("Error", res.response.data.full_error);
        return res.response.data;
      });
  }

  static getAgentChannels(agent_id: number): Promise<TaskChannel[]> {
    return Api.get("/agents/" + agent_id + "/channels").then(
      (res: IAxiosAgentChannels): TaskChannel[] => {
        return res.data.map((d: ITaskChannel): TaskChannel => {
          return new TaskChannel(d);
        });
      }
    );
  }
  static getAgents(filter: string): Promise<IAxiosAgents> {
    let urlParams = new URLSearchParams(filter);
    return Api.get("/agents?" + urlParams);
  }

  static updateAgentChannels(
    agent_id: number,
    channel: TaskChannelChanges
  ): Promise<TaskChannel> {
    return Api.put(
      "/agents/" + agent_id + "/channels/" + channel.Sid,
      channel
    ).then((res: IAxiosAgentChannel): TaskChannel => {
      return new TaskChannel(res.data);
    });
  }

  static getCustomKPIs() {
    return Api.get("/custom-kpis");
  }

  static getAgentCustomKPIs(agentId: number) {
    return Api.get("/agents/" + agentId + "/custom-kpis");
  }

  static storeCustomKPIs(agentId: number, kpis: AgentCustomKPI[]) {
    return Api.put("/agents/" + agentId + "/custom-kpis", { kpis: kpis });
  }
}
