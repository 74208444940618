import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core"
import React, { useState } from "react"
import { RedBtn, RedInverseBtn } from "../Styled/StyledButtons"
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css'
import styles from '../TeamsManagement/styles.module.css'
import { Phone } from "../../models/Whitelist/Phone"
import ClearIcon from '@material-ui/icons/Clear';

interface IProps {
    toggleHandle: () => void
    whitelistPhone: Phone | null
    show: boolean
    handleDelete: (phone: Phone) => void
}

const RemoveFromWhitelist = (props: IProps) => {
    const [comment, setComment] = useState(props.whitelistPhone ? props.whitelistPhone.comment : '')

    const deletePhone = () => {
        if(validateComment()){
            props.handleDelete({ phone: props.whitelistPhone!.phone, comment, id: props.whitelistPhone?.id  })
        props.toggleHandle()
    }
    }
    const validateComment = () => {
        return comment !== undefined && comment.length > 3
    }

    return (
        <Dialog open={props.show} onClose={props.toggleHandle}>
            <DialogTitle>
                {'Remove "' + props.whitelistPhone?.phone + '" from whitelist?'}
                <IconButton className={mainStyles.floatRight} onClick={props.toggleHandle}>
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.popup}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <label className={styles.FormLabel}>Comment</label>
                        <input placeholder="Comment"
                            className={`${(!validateComment()) ? styles.FormInvalid : ""} ${styles.FormControl} `}
                            value={comment} onChange={(event) => setComment(event.target.value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} >
                    <Grid item xs={6}  >
                        <RedInverseBtn fullWidth={true} onClick={props.toggleHandle}>Cancel</RedInverseBtn>
                    </Grid>
                    <Grid item xs={6}  >
                        <RedBtn fullWidth={true} onClick={() => deletePhone()}>Delete</RedBtn>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveFromWhitelist