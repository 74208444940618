import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';
import { Backdrop, CircularProgress, Container, Grid, Snackbar, Tooltip } from '@material-ui/core';
import { RedInverseBtn, BlueBtn } from '../Styled/StyledButtons';
import { CustomSkill } from '../../models/AgentSkills/CustomSkill';
import { Department } from '../../models/AgentSkills/Department';
import { ExpressionConfigProperty, ExpressionOption, IExpressionConfig } from '../../models/ExpressionBuilder/ExpressionBuilderTypes';
import { Activity } from '../../models/Workspaces/Activity';
import { Queue } from '../../models/Queue/Queue';
import { WorkspacesActions } from '../../actions/WokspacesActions';
import ExpressionComponent from '../ExpressionComponent/ExpressionComponent';
import { QueuesManagementActions } from '../../actions/QueuesManagementActions';
import { AgentSkillsActions } from '../../actions/AgentSkillsActions';
import { DepartmentsActions } from '../../actions/DepartmentsActions';
import ConfirmComponent from '../ConfirmComponent/ConfirmComponent';
import { GUID } from '../../models/Guid';
import { ExpressionValidator } from '../../models/ExpressionBuilder/ExpressionValidator';
import { Alert } from '@material-ui/lab';
import { QueuesWorkersTargetConverter } from '../../models/ExpressionBuilder/QueuesWorkersTargetConverter';
import { HumanFriendlyExpressionConverter } from '../../models/ExpressionBuilder/HumanFriendlyExpressionConverter';

interface IState {
    discardConfirmShow: boolean,
    currentWorkspace: string,
    activities: Activity[],
    queue: Queue,
    queueSid: string,
    departments: Department[],
    customSkills: CustomSkill[],
    config: IExpressionConfig,
    defaultData: Object,
    backdrop: boolean,
    alert: boolean,
    errorAlert: boolean,
    errors: string[],
    alertErrors: string[],
    saving: boolean
}
export default class QueuesEditCompomponent extends React.Component<any, IState> {

    state: IState = {
        discardConfirmShow: false,
        currentWorkspace: '',
        activities: [],
        queueSid: '',
        queue: new Queue(undefined),
        departments: [],
        customSkills: [],
        config: {
            fields: {
                categories: { name: 'Departments', type: 'category', options: [{ name: 'cat1', value: '1' }, { name: 'cat2', value: '2' }, { name: 'catN', value: 'n' }], operators: ['in', 'not in', 'has'] },
                skills: { name: 'Activities', type: 'category', options: [{ name: 'Booking', value: 'booking' }, { name: 'Complains', value: 'complains' }, { name: 'Support', value: 'support' }, { name: 'Membership', value: 'membership' }, { name: 'Voicemail', value: 'voicemail' }, { name: 'Marketing', value: 'marketing' }, { name: 'Dsr', value: 'dsr' }, { name: 'Reception', value: 'reception' }], operators: ['in', 'not in', 'has'] },
                best_booker: { name: 'Best Booker', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                cancelation_ratio: { name: 'Cancelation ratio', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                best_sel: { name: 'Best Seller', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }]},
                adl: { name: 'ADL', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },                
                professional: { name: 'Group A (Professional)', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                amateur: { name: 'Group B (Amateur)', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                beginner: { name: 'Group C (Beginner)', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                group_d: { name: 'Group D', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                group_e: { name: 'Group E', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                group_f: { name: 'Group F', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                avg_call_value_scaled: { name: 'AVG Call Value (scaled)', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] },                
                score_monthly: { name: 'Score Monthly', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] },                
                score_weekly: { name: 'Score Weekly', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] }
            }
        },
        defaultData: {
            condition: 'and',
            rules: [
                { field: 'categories', operator: 'in' },
                { field: 'skills', operator: 'in' },
            ]
        },
        alert: false,
        errorAlert: false,
        alertErrors: [],
        errors: [],
        saving: false,
        backdrop: (this.props.match.params.sid !== undefined),
    }

    constructor(props: any) {
        super(props)
        this.handleExpression = this.handleExpression.bind(this)
    }

    loadWorkspaces() {
        WorkspacesActions.getWorkspaces()
            .then(workspaces => {
                if (workspaces.length > 0) {
                    this.setState({ currentWorkspace: workspaces[0].Sid })
                    this.loadActivities()
                    let sid = this.props.match.params.sid
                    if (sid !== undefined) {
                        this.setState({ queueSid: sid })
                        this.loadQueue()
                    } else {
                        document.title = 'Add new Queue'
                    }
                }
            })
    }
    loadActivities() {
        WorkspacesActions.getActivities(this.state.currentWorkspace)
            .then(activities => { this.setState({ activities: activities }) })
    }
    componentDidMount() {
        this.loadSkills()
        this.loadDepartments()
        this.loadWorkspaces()
    }
    loadQueue() {
        QueuesManagementActions.getQueue(this.state.currentWorkspace, this.state.queueSid)
            .then((data) => {
                if (data instanceof Queue) {
                    document.title = data.Name + ' | Edit Queue'
                    this.setState({ queue: data, backdrop: false })
                } else {
                    let errors = (data.errors === undefined) ? [data.error] : data.errors
                    this.setState({ errorAlert: true, backdrop: false, errors: errors })
                }
            })
    }
    loadSkills() {
        AgentSkillsActions.getSkills()
            .then(skills => {
                let customSkills: ExpressionConfigProperty[] = []
                let config = this.state.config
                let confProp = new ExpressionConfigProperty()
                for (let key in config.fields) {
                    confProp[key] = config.fields[key]
                }

                skills.forEach(element => {
                    let type = this.getExpressionElementType(element.TypeId)
                    confProp[element.Key] = { name: element.Key, type: type, operators: ['='] }
                    customSkills.push(confProp)
                });
                config.fields = confProp

                this.setState({
                    customSkills: skills,
                    config: config
                })
            })
    }

    getExpressionElementType(typeId: number) {
        let type: string = 'string'
        switch (typeId) {
            case 1:
                type = 'string'
                break
            case 2:
                type = 'number'
                break
            default:
                type = 'string'
                break
        }
        return type
    }
    loadDepartments() {
        DepartmentsActions.getDepartments()
            .then(departments => {
                let deps: ExpressionOption[] = []

                departments.forEach((item, index) => {
                    deps.push(new ExpressionOption(item.Name, item.Id))
                })

                let config = this.state.config
                config.fields.categories.options = deps
                this.setState({
                    departments: departments,
                    config: config
                })
            }
            )
    }

    saveQueue() {
        if (this.validate()) {
            this.setState({ saving: true })
            if (this.state.queue.Sid !== "") {
                QueuesManagementActions.updateQueue(this.state.currentWorkspace, this.state.queue.Sid, this.state.queue)
                    .then((data) => {
                        if (data instanceof Queue) {
                            this.setState({ saving: false })
                            this.goBack()
                        } else {
                            let errors = (data.errors === undefined) ? [data.error] : data.errors
                            this.setState({ errorAlert: true, alertErrors: errors, saving: false })
                        }
                    })
            } else {
                QueuesManagementActions.createQueue(this.state.currentWorkspace, this.state.queue)
                    .then((data) => {
                        if (data instanceof Queue) {
                            this.setState({ saving: false })
                            this.goBack()
                        } else {
                            let errors = (data.errors === undefined) ? [data.error] : data.errors
                            this.setState({ errorAlert: true, alertErrors: errors, saving: false })
                        }
                    })
            }
        }
    }
    handleMaxReserved(value: string) {
        const maxReserved = parseInt(value)
        let o = this.state.queue
        if (maxReserved > 0 && maxReserved < 51) {
            o.MaxReserved = maxReserved
            this.setState({ queue: o })
        }

    }
    handleName(value: string) {
        let o = this.state.queue
        o.Name = value
        this.setState({ queue: o })
    }
    handleOrder(value: string) {
        let o = this.state.queue
        o.Order = value
        this.setState({ queue: o })
    }

    handleExpression(exp: string) {
        let errors: string[] = ExpressionValidator.Validate(JSON.parse(exp))
        this.setState({ errors: errors });
        if (errors.length === 0) {
            let o = this.state.queue
            o.Expression = exp
            o.TargetWorkers = QueuesWorkersTargetConverter.ConvertToTwilio(JSON.parse(exp))
            this.setState({ queue: o })
        }

    }
    handleAssignmentChange(value: string) {
        let o = this.state.queue
        o.Assignment = value
        this.setState({ queue: o })
    }
    handleReservationChange(value: string) {
        let o = this.state.queue
        o.Reservation = value
        this.setState({ queue: o })
    }
    goBack() {
        this.props.history.push('/queues');
    }

    closeDiscardConfirm() {
        this.setState({ discardConfirmShow: false })
    }
    showDiscardConfirm() {
        this.setState({ discardConfirmShow: true })
    }
    handleClose() {
        this.setState({ alert: false })
    }
    handleCloseError() {
        this.setState({ errorAlert: false })
    }
    validate() {
        let valid: boolean = true
        valid = valid && (this.state.queue.Name !== '')
        valid = valid && (this.state.queue.MaxReserved > 0)
        valid = valid && (this.state.queue.Assignment !== '')
        valid = valid && (this.state.queue.Reservation !== '')
        valid = valid && (this.state.queue.TargetWorkers !== '')
        valid = valid && (this.state.errors.length === 0)
        return valid
    }
    render() {
        const expression = () => { return (this.state.queue.Expression !== '') ? HumanFriendlyExpressionConverter.ConvertToTwilio(JSON.parse(this.state.queue.Expression), '', this.state.config) : '' }
        return (
            <div>
                <Container maxWidth="md">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className={mainStyles.Header}>Queues Management</div>
                                    <div className={mainStyles.AgentName}> {(this.state.queue.Sid === '') ? 'Add new Queue' : this.state.queue.Name}</div>
                                </Grid>
                            </Grid>
                            <div className={styles.ComponentBody}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <label className={styles.FormLabel}>Queue name</label>
                                                <input placeholder="Queue Name" className={`${(this.state.queue.Name === '') ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={this.state.queue.Name} onChange={event => this.handleName(event.target.value)} />

                                            </Grid>
                                            <Grid item xs={12}>
                                                <label className={styles.FormLabel}>Max Reserved Agents</label>
                                                <input type="number" className={`${(this.state.queue.MaxReserved === 0) ? styles.FormInvalid : ""} ${styles.FormControl}  ${styles.FullWidth}`} min="0" max="50" value={this.state.queue.MaxReserved} onChange={event => this.handleMaxReserved(event.target.value)} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={4}>
                                                <label className={styles.FormLabel}>Task order  <Tooltip title='First In - First Out OR Last In - First Out' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                                <div className={styles.SwitchContainer}>
                                                    <div className={styles.Switch} >
                                                        <input type="radio" id="switch_left" name="switch" value="FIFO" checked={this.state.queue.Order === "FIFO"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOrder(event.target.value)} />
                                                        <label htmlFor="switch_left">FIFO</label>
                                                        <input type="radio" id="switch_right" name="switch" value="LIFO" checked={this.state.queue.Order === "LIFO"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOrder(event.target.value)} />
                                                        <label htmlFor="switch_right">LIFO</label>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <label className={styles.FormLabel}>Assignment activity</label>
                                                <select className={`${(this.state.queue.Assignment === '')} ${styles.FormControl}  ${styles.FullWidth}`} value={this.state.queue.Assignment} onChange={event => this.handleAssignmentChange(event.target.value)}>
                                                    <option value="" disabled >Chose Assignment activity</option>
                                                    {this.state.activities.map((row, index) => (
                                                        <option key={row.Sid} value={row.Sid} >{row.Name}</option>
                                                    ))}
                                                </select>

                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <label className={styles.FormLabel}>Reservation activity</label>
                                                <select className={`${(this.state.queue.Reservation === '') ? styles.FormInvalid : ""}} ${styles.FormControl}  ${styles.FullWidth}`} value={this.state.queue.Reservation} onChange={event => this.handleReservationChange(event.target.value)}>
                                                    <option value="" disabled >Chose Reservation activity</option>
                                                    {this.state.activities.map((row, index) => (
                                                        <option key={row.Sid} value={row.Sid} >{row.Name}</option>
                                                    ))}
                                                </select>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <label className={styles.FormLabel}>Target Workers <Tooltip title='An expression that matches a set of Workers. Workers will be added and removed from queues as their attributes are updated.' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                                <div className={`${(this.state.queue.TargetWorkers === '') ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`}
                                                    dangerouslySetInnerHTML={{ __html: expression() }} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ul>
                                                    {this.state.errors.map((error: string) => (
                                                        <li className={styles.ExpressionError} key={GUID.New()}>{error}</li>
                                                    ))}
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <ExpressionComponent dataOut={(expr: string) => this.handleExpression(expr)} data={this.state.queue.Expression || JSON.stringify(this.state.defaultData)} config={JSON.stringify(this.state.config)} />
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}><div className={styles.ButtonMargin}><RedInverseBtn fullWidth={true} onClick={() => this.showDiscardConfirm()}>Discard</RedInverseBtn></div></Grid>
                                <Grid item xs={12} md={6} ><div className={styles.ButtonMargin}><BlueBtn variant="contained" fullWidth={true} onClick={() => this.saveQueue()}><CircularProgress className={` ${styles.SaveProgress} ${(this.state.saving) ? '' : styles.DisplayNone}`} />&nbsp;&nbsp;Save</BlueBtn></div></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <ConfirmComponent show={this.state.discardConfirmShow} title="Discard changes" bodyText="Are you sure you want to discard unsaved changes?" close={() => this.closeDiscardConfirm()} confirm={() => this.goBack()}></ConfirmComponent>
                <Snackbar open={this.state.alert} autoHideDuration={5000} onClose={() => this.handleClose()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                    <Alert onClose={() => this.handleClose()} severity="success" variant="filled">
                        Saved!</Alert>
                </Snackbar>
                <Snackbar open={this.state.errorAlert} autoHideDuration={5000} onClose={() => this.handleCloseError()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                    <Alert onClose={() => this.handleCloseError()} severity="error" variant="filled">
                        Error<br />
                        <div className={styles.AlertError}>{this.state.alertErrors}</div>
                    </Alert>
                </Snackbar>
                <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
                    <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
          <p className={styles.BackdropProgress}>Loading...</p>
                </Backdrop>
            </div>
        )
    }
}

