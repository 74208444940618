import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { BlueBtn, RedInverseBtn } from "../Styled/StyledButtons"
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css'
import styles from '../TeamsManagement/styles.module.css'
import { Phone } from "../../models/Whitelist/Phone"
import ClearIcon from '@material-ui/icons/Clear';
import InputMask from "react-input-mask";

interface IProps {
    toggleHandle: () => void
    whitelistPhone: Phone | null
    show: boolean
    handleSave: (phone: Phone) => void
}

const AddToWhitelist = (props: IProps) => {
    const [phone, setPhone] = useState(props.whitelistPhone ? props.whitelistPhone.phone : '')
    const [comment, setComment] = useState(props.whitelistPhone ? props.whitelistPhone.comment : '')

    const save = () => {
        if (validatePhone() && validateComment()) {
            props.handleSave({ phone, comment, id: props.whitelistPhone?.id })
            props.toggleHandle()
        }
    }
    const validatePhone = () => {
        if (props.whitelistPhone !== null) {
            return true
        }
        return phone !== undefined && /\([0-9]{3}\) [0-9]{3}\-[0-9]{4}/.test(phone)
    }

    const validateComment = () => {
        return comment !== undefined && comment.length > 3
    }

    return (
        <Dialog open={props.show} onClose={props.toggleHandle}>
            <DialogTitle>
                {props.whitelistPhone === null ? 'Add phone to Whitelist' :
                    props.whitelistPhone.deleted_at === null ? 'Edit phone "' + props.whitelistPhone?.phone + '"' : 'Restore phone "' + props.whitelistPhone?.phone + '"'}
                <IconButton className={mainStyles.floatRight} onClick={props.toggleHandle}>
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.popup}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {props.whitelistPhone === null ?
                            <>
                                <label className={styles.FormLabel}>Phone</label>
                                <InputMask
                                    mask="(999) 999-9999"
                                    value={phone}
                                    disabled={props.whitelistPhone !== null}
                                    maskChar=" "                                    
                                    onChange={(event) => setPhone(event.target.value)}                                    
                                >
                                    {() => <input className={`${(!validatePhone()) ? styles.FormInvalid : ""} ${styles.FormControl} `} />}
                                </InputMask>
                            </>
                            : ''}
                    </Grid>


                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <label className={styles.FormLabel}>Comment</label>
                        <input placeholder="Comment"
                            className={`${(!validateComment()) ? styles.FormInvalid : ""} ${styles.FormControl} `}
                            value={comment} onChange={(event) => setComment(event.target.value)} />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} >
                    <Grid item xs={6}  >
                        <RedInverseBtn fullWidth={true} onClick={props.toggleHandle}>Cancel</RedInverseBtn>
                    </Grid>
                    <Grid item xs={6}  >
                        <BlueBtn fullWidth={true} onClick={() => save()}>Save</BlueBtn>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default AddToWhitelist