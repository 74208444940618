import { CircularProgress, Container, Grid } from "@material-ui/core"
import React from "react"
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css'
import styles from '../AgentsComponent/styles.module.css'
import ClearIcon from '@material-ui/icons/Clear';
import { Add } from "@material-ui/icons";
import { BlueInverseBtn } from "../Styled/StyledButtons";

interface IProps {
    handleAdd: () => void
    handleChangeSearch: (search: string) => void
    search: string
    searchStatus: string
}

const ControlPanel = (props:IProps) => {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={1}>
                <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>

                            <div className={styles.filters__search}>
                                {props.searchStatus !== 'loading' ?
                                    <span
                                        className={styles.filters__search_clear}
                                        onClick={() => props.handleChangeSearch('')}>
                                        <ClearIcon className={styles.filters__search_loading} />
                                    </span>
                                    :
                                    <span className={styles.filters__renderSearch__loading}>
                                        <CircularProgress size={24} className={styles.filters__search_loading} />
                                    </span>
                                }
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => props.handleChangeSearch(e.target.value)}
                                    value={props.search}
                                    className={styles.filters__search__inputSearch}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlueInverseBtn onClick={()=>props.handleAdd()} variant="contained" fullWidth={true}><Add /> Add Phone</BlueInverseBtn>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
export default ControlPanel