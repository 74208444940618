import Api from "../config/api";
import { IError } from "../models/IError";
import { IWorkflow, Workflow } from "../models/Workflows/Workflows";
import history from './../components/history';

interface IAxiosWorkflows{
    data: IWorkflow[]
}

interface IAxiosWorkflow{
    data: IWorkflow
}

interface IAxiosError {
    response: { data: IError, status: number }
}

export class WorkflowsManagementActions{ 
    static getWorkflows(sid:string): Promise<Workflow[]>{
        return Api.get("/workspaces/"+sid+"/workflows")
        .then((res:IAxiosWorkflows):Workflow[]=> {return res.data.map((d:IWorkflow):Workflow => { return new Workflow(d)})})
    }
    static getWorkflow(sid: string, workflow_sid:string): Promise<Workflow | IError>{
        return Api.get("/workspaces/"+sid+"/workflows/"+workflow_sid)
        .then((res:IAxiosWorkflow):Workflow=>{return new Workflow(res.data)})
        .catch((res:IAxiosError):IError=> { if(res.response.status === 404){history.replace("/404");} return res.response.data})
    }
    static createWorkflow(sid: string, workflow: Workflow): Promise<Workflow | IError>{
        return Api.post("/workspaces/"+sid+"/workflows", workflow)
        .then((res:IAxiosWorkflow):Workflow=> {return new Workflow(res.data)})
        .catch((res:IAxiosError):IError=> { console.log('Error', res.response.data.full_error); return res.response.data})     
    }
    static updateWorkflow(sid: string, workflow_sid:string, workflow: Workflow): Promise<Workflow | IError>{
        return Api.put("/workspaces/"+sid+"/workflows/"+workflow_sid, workflow)
        .then((res:IAxiosWorkflow):Workflow=> {return new Workflow(res.data)})
        .catch((res:IAxiosError):IError=> { console.log('Error', res.response.data.full_error); return res.response.data}) 
    }
    static deleteWorkflow(sid: string, workflow_sid:string):Promise<boolean | IError> {
        return Api.delete("/workspaces/"+sid+"/workflows/"+workflow_sid)
        .then((res:IAxiosWorkflow): boolean => { return res.data.sid !== undefined })
        .catch((res:IAxiosError):IError=> { console.log('Error', res.response.data.full_error); return res.response.data})
    }
    static getFlow(): Promise<string | IError>{
        return Api.get("/workflows-set-flow")
        .then((res:any):string=>{return res.data.flow})
        .catch((res:IAxiosError):IError=> { console.log('Error', res.response.data.full_error); return res.response.data})
    }
    static changeFlow(flow: string): Promise<string | IError>{
        return Api.post("/workflows-set-flow", {flow: flow})
        .then((res:any):string=> {return res.data.flow})
        .catch((res:IAxiosError):IError=> { console.log('Error', res.response.data.full_error); return res.response.data}) 
    }
    

}