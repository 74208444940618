import Api from "./../config/api";

interface IAxiosTasks {
    data: any[]
}

export class WhiteListActions {
    static getWhitelist(page?: number, search?: string): Promise<any[]> {
        const query: string[] = []
        if (page && page > 0 && page != 1) { query.push(`page=${page}`) }
        if (search && search !== '') { query.push(`search=${search}`) }
        const queryParams: string = query.length > 0 ? `?${query.join('&')}` : ''
        return Api.get("/whitelist" + queryParams)
            .then((res: IAxiosTasks) => { return res.data })
    }
    static createWhitelist(phone: string, comment: string): Promise<any[]> {
        return Api.post("/whitelist", { phone, comment })
            .then((res: IAxiosTasks) => { return res.data })
    }
    static updateWhitelist(id: number, comment: string): Promise<any[]> {
        return Api.put("/whitelist/" + id, { comment })
            .then((res: IAxiosTasks) => { return res.data })
    }
    static deleteWhitelist(id: number, comment: string): Promise<any[]> {
        return Api.delete("/whitelist/" + id, { data: { comment } })
            .then((res: IAxiosTasks) => { return res.data })
    }
    static getPhone(phone: string): Promise<any[]> {
        return Api.get("/whitelist/" + phone)
            .then((res: IAxiosTasks) => { return res.data })
    }
}