import React from 'react';
import styles from './styles.module.css';
import { Container, Grid, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { RoutingStep } from '../../models/Workflows/RoutingStep';
import { Queue } from '../../models/Queue/Queue';
import SkipTimeoutExpressionComponent from './SkipTimeoutExpressionComponent';
import { WorkersTargetConverter } from '../../models/ExpressionBuilder/WorkersTargetConverter';
import OrderingExpressionComponent from './OrderingExpressionComponent';
import { AgentSkillsActions } from '../../actions/AgentSkillsActions';
import { ExpressionConfigProperty, ExpressionOption, IExpressionConfig } from '../../models/ExpressionBuilder/ExpressionBuilderTypes';
import { DepartmentsActions } from '../../actions/DepartmentsActions';
import StepExpresionComponent from './StepExpresionComponent';
import { WorkspacesActions } from '../../actions/WokspacesActions';
import { HumanFriendlyExpressionConverter } from '../../models/ExpressionBuilder/HumanFriendlyExpressionConverter';

export interface IProps {
    currentWorkspace: string,
    target: RoutingStep,
    handleDelete: (target: RoutingStep) => void,
    handleChange: (target: RoutingStep) => void,
    queues: Queue[]
}

interface IState {
    target: RoutingStep,
    showSkipTimeout: boolean,
    showExpression: boolean,
    showOrdering: boolean,
    showTaskExpression: boolean,
    minimized: boolean,
    stepExpressionConfig: IExpressionConfig,
    stepExpressionDefaultData: object,
    skipTimeoutConfig: IExpressionConfig,
    skipTimeoutDefaultData: object,
    workflowExpressionConfig: IExpressionConfig,
    workflowExpressionDefaultData: object,
}

export default class WorkflowStepComponent extends React.Component<IProps, IState> {
    state: IState = {
        showSkipTimeout: false,
        showExpression: false,
        showOrdering: false,
        showTaskExpression: false,
        target: new RoutingStep(undefined),
        minimized: true,
        stepExpressionConfig: {
            fields: {
                exist_customer: {name: 'Exist Customer', type: 'boolean', operators: ['='] },
                categories: { name: 'Departments', type: 'category', options: [{ name: 'cat1', value: '1' }, { name: 'cat2', value: '2' }, { name: 'catN', value: 'n' }], operators: ['in', 'not in', 'has'] },
                skills: { name: 'Activities', type: 'category', options: [{ name: 'Booking', value: 'booking' }, { name: 'Complains', value: 'complains' }, { name: 'Support', value: 'support' }, { name: 'Membership', value: 'membership' }, { name: 'Voicemail', value: 'voicemail' }, { name: 'Dsr', value: 'dsr' }, { name: 'Reception', value: 'reception' }], operators: ['in', 'not in', 'has'] },
                best_booker: { name: 'Best Booker', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                cancelation_ratio: { name: 'Cancelation ratio', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                best_sel: { name: 'Best Seller', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }]},
                adl: { name: 'ADL', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                professional: { name: 'Group A (Professional)', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                amateur: { name: 'Group B (Amateur)', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                beginner: { name: 'Group C (Beginner)', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                group_d: { name: 'Group D', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                group_e: { name: 'Group E', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                group_f: { name: 'Group F', type: 'category', operators: ['='] , options: [{ name: 'true', value: 1 }, { name: 'false', value: 0 }] },
                avg_call_value_scaled: { name: 'AVG Call Value (scaled)', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] },                
                score_monthly: { name: 'Score Monthly', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] },                
                score_weekly: { name: 'Score Weekly', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] }
            }
        },
        stepExpressionDefaultData: {
            condition: 'and',
            rules: []
        },
        skipTimeoutConfig: {
            fields: {
                available: { name: 'Available', type: 'number', operators: ['=', '<=', '>=', '<', '>'] },
                unavailable: { name: 'Unavailable', type: 'number', operators: ['=', '<=', '>=', '<', '>'] },
            }
        },
        skipTimeoutDefaultData: {
            condition: 'and',
            rules: []
        },
        workflowExpressionConfig: {
            fields: {
                category: { name: 'Departments', type: 'category', options: [], operators: ['='] },
                task_for: { name: 'Activities', type: 'category', options: [{ name: 'Booking', value: 'booking' }, { name: 'Complains', value: 'complains' }, { name: 'Support', value: 'support' }, { name: 'Membership', value: 'membership' }, { name: 'Voicemail', value: 'voicemail' }, { name: 'Dsr', value: 'dsr' }, { name: 'Reception', value: 'reception' }], operators: ['='] },
                is_member: { name: 'Is Member', type: 'boolean', operators: ['='] },
                avg_call_value_scaled: { name: 'AVG Call Value (scaled)', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] },
            }
        },
        workflowExpressionDefaultData: {
            condition: 'and',
            rules: [
                { field: 'category', operator: '=' },
                { field: 'task_for', operator: '=' },
                { field: 'is_member', operator: '=', value: false },
            ]
        },
    }

    timeouts = [
        { timeout: 0, name: "0 seconds" },
        { timeout: 5, name: "5 seconds" },
        { timeout: 10, name: "10 seconds" },
        { timeout: 15, name: "15 seconds" },
        { timeout: 20, name: "20 seconds" },
        { timeout: 30, name: "30 seconds" },
        { timeout: 45, name: "45 seconds" },
        { timeout: 60, name: "60 seconds" },
        { timeout: 90, name: "90 seconds" },
        { timeout: 120, name: "120 seconds" },
        { timeout: 150, name: "150 seconds" },
        { timeout: 180, name: "180 seconds" },
    ]

    componentDidMount() {
        this.loadSkills()
        this.loadDepartments()
        this.loadActivities()
        const step = this.props.target
        step.TaskExpression = '1==1'
        step.TaskExpressionJson = '{"condition":"and","rules":[]}'
        this.setState({ target: this.props.target })
    }
    loadSkills() {
        AgentSkillsActions.getSkills()
            .then(skills => {
                let stepConfig = this.state.stepExpressionConfig
                let stepConfigProp = new ExpressionConfigProperty()
                for (let key in stepConfig.fields) {
                    stepConfigProp[key] = stepConfig.fields[key]
                }

                let workflowConfig = this.state.workflowExpressionConfig
                let workflowConfigProp = new ExpressionConfigProperty()
                for (let key in workflowConfig.fields) {
                    workflowConfigProp[key] = workflowConfig.fields[key]
                }

                skills.forEach(element => {
                    let type = this.getExpressionElementType(element.TypeId)
                    let operators = (type === 'number')? ['=', '<=', '>=', '<', '>'] : ['=']
                    stepConfigProp[element.Key] = { name: element.Key, type: type, operators: operators }
                    workflowConfigProp[element.Key] = { name: element.Key, type: type, operators: operators }
                });
                stepConfig.fields = stepConfigProp
                workflowConfig.fields = workflowConfigProp


                this.setState({
                    stepExpressionConfig: stepConfig,
                    workflowExpressionConfig: workflowConfig
                })
            })
    }
    getExpressionElementType(typeId: number) {
        let type: string = 'string'
        switch (typeId) {
            case 1:
                type = 'string'
                break
            case 2:
                type = 'number'
                break
            default:
                type = 'string'
                break
        }
        return type
    }
    loadDepartments() {
        DepartmentsActions.getDepartments()
            .then(departments => {
                let deps: ExpressionOption[] = []

                departments.forEach((item) => {
                    deps.push(new ExpressionOption(item.Name, item.Id))
                })

                let stepConfig = this.state.stepExpressionConfig
                stepConfig.fields.categories.options = deps

                let workflowConfig = this.state.workflowExpressionConfig
                workflowConfig.fields.category.options = deps

                this.setState({ stepExpressionConfig: stepConfig, workflowExpressionConfig: workflowConfig })
            }
            )
    }
    loadActivities() {
        WorkspacesActions.getActivities(this.props.currentWorkspace)
            .then(activities => {
                let stepConfig = this.state.skipTimeoutConfig
                let confProp = new ExpressionConfigProperty()
                for (let key in stepConfig.fields) {
                    confProp[key] = stepConfig.fields[key]
                }

                activities.forEach(element => {
                    confProp[element.Sid] = { name: element.Name, type: 'number', operators: ['=', '<=', '>=', '<', '>'] }
                });
                stepConfig.fields = confProp

                this.setState({ skipTimeoutConfig: stepConfig })
            })
    }
    handleName(name: string) {
        let o = this.state.target
        o.Name = name
        this.setState({ target: o })
        this.props.handleChange(o)
    }
    handleQueue(sid: string, name: string) {
        let o = this.state.target
        o.QueueSid = sid
        o.QueueName = name
        this.setState({ target: o })
        this.props.handleChange(o)
    }
    handleTimeout(timeout: string) {
        let o = this.state.target
        o.Timeout = parseInt(timeout)
        this.setState({ target: o })
        this.props.handleChange(o)
    }
    handlePriority(priority: number) {
        if (priority > 0) {
            let o = this.state.target
            o.Priority = priority
            this.setState({ target: o })
            this.props.handleChange(o)
        }
    }
    handleExpression(expressionJson: string) {
        let o = this.state.target
        o.ExpressionJson = expressionJson
        o.Expression = WorkersTargetConverter.ConvertToTwilio(JSON.parse(expressionJson), 'worker')
        this.setState({ target: o })
        this.props.handleChange(o)
        this.setState({ showExpression: false })
    }
    toggleExpression() {
        this.setState({ showExpression: !this.state.showExpression })
    }
    handleSkipTimeout(expressionJson: string) {
        let o = this.state.target
        o.SkipTimeoutJson = expressionJson
        o.SkipTimeout = WorkersTargetConverter.ConvertToTwilio(JSON.parse(expressionJson), 'workers')
        this.setState({ target: o })
        this.props.handleChange(o)
        this.setState({ showSkipTimeout: false })
    }
    toggleSkipTimeout() {
        this.setState({ showSkipTimeout: !this.state.showSkipTimeout })
    }
    handleOrdering(expressionJson: string) {
        let o = this.state.target
        o.OrderingJson = expressionJson
        o.Ordering = WorkersTargetConverter.ConvertSortingToTwilio(JSON.parse(expressionJson), 'worker')
        this.setState({ target: o })
        this.props.handleChange(o)
        this.setState({ showOrdering: false })
    }
    toggleOrdering() {
        this.setState({ showOrdering: !this.state.showOrdering })
    }
    
    
    toggleMinimize() {
        this.setState({ minimized: !this.state.minimized })
    }
    render() {
        return (
            <div className={` ${styles.RouteStepContainer} ${((this.state.target.Name === '') || (this.state.target.TaskExpression === '') || (this.state.target.TaskExpression === '') || (this.state.target.QueueSid === '')) ? styles.FormInvalid : ''}`}>
                <Container className={styles.WorkerManagementComponent}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} onClick={() => { this.toggleMinimize() }}>
                            <DragIndicatorIcon className={styles.DragIcon} />
                            <label className={styles.StepFriendlyName}>{this.state.target.Name}</label>
                            <KeyboardArrowUpIcon className={`${styles.MinimazeIcon} ${this.state.minimized ? styles.DisplayNone : ''}`} onClick={() => { this.toggleMinimize() }} />
                            <KeyboardArrowDownIcon className={`${styles.MinimazeIcon} ${!this.state.minimized ? styles.DisplayNone : ''}`} onClick={() => { this.toggleMinimize() }} />
                            <ClearIcon className={styles.RemoveIcon} onClick={() => { this.props.handleDelete(this.state.target) }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={`${this.state.minimized ? styles.DisplayNone : ''}`}>
                        <Grid item xs={12} md={6}>
                            <div>
                                <label className={styles.StepFormLabel}>Friendly Name <Tooltip title='Step Friendly Name' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                <input placeholder="Friendly Name" className={`${(this.state.target.Name === '') ? styles.FormInvalid : ""} ${styles.FullWidth} ${styles.FormControl}`} value={this.state.target.Name} onChange={event => this.handleName(event.target.value)} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                           
                        </Grid>
                       
                        <Grid item xs={12} md={6}><div className={styles.SubHead}>Matching workers</div></Grid>
                        <Grid item xs={12} md={6}><div className={styles.SubHead}>Timeout & Priority</div></Grid>

                        <Grid item xs={12} md={6}>

                            <div>
                                <label className={styles.StepFormLabel}>Queue <Tooltip title='The set of Workers eligible to match a task. Use previous step`s queue if none provided.' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                <select className={`${(this.state.target.QueueSid === '') ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={this.props.target.QueueSid} onChange={event => this.handleQueue(event.target.value as string, event.target.name as string)} >
                                    <option value="" disabled>(select queue)</option>
                                    {this.props.queues.map((row, index) => (
                                        <option key={row.Sid} value={row.Sid} >{row.Name}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className={styles.StepFormLabel}>Expression <Tooltip title='Match a subset of Workers from within the selected queue.' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                <div 
                                className={`${(this.state.target.Expression === '') ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} 
                                dangerouslySetInnerHTML={{ __html: (this.state.target.ExpressionJson !== '') ? HumanFriendlyExpressionConverter.ConvertToTwilio(JSON.parse(this.state.target.ExpressionJson), 'worker', this.state.stepExpressionConfig):''} }
                                onClick={() => this.toggleExpression()} />
                            </div>
                            <div>
                                <label className={styles.StepFormLabel}>Ordering <Tooltip title='Sort the matching Workers by their attributes when creating Reservations.' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                <div className={`${styles.FormControl} ${styles.FullWidth}`}
                                 dangerouslySetInnerHTML={{ __html: (this.state.target.OrderingJson !== '') ? HumanFriendlyExpressionConverter.ConvertSortingToTwilio(JSON.parse(this.state.target.OrderingJson), 'workers'):''}} 
                                 onClick={() => this.toggleOrdering()} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div>
                                <label className={styles.StepFormLabel}>Skip timeout <Tooltip title='If no matching Worker is found immediately, do not wait for timeout and skip to the next routing step.' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                <div 
                                className={` ${styles.FormControl} ${styles.FullWidth}`} 
                                dangerouslySetInnerHTML={{ __html: (this.state.target.SkipTimeoutJson !== '') ? HumanFriendlyExpressionConverter.ConvertToTwilio(JSON.parse(this.state.target.SkipTimeoutJson), 'worker', this.state.skipTimeoutConfig):''} }
                                onClick={() => this.toggleSkipTimeout()} />

                            </div>
                            <div>
                                <label className={styles.StepFormLabel}>Timeout <Tooltip title='The number of seconds to wait in this routing step while attempting to find a matching Worker. Will wait until Task Timeout if not provided.' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                <select className={`${styles.FormControl}  ${styles.FullWidth}`} value={this.state.target.Timeout} onChange={event => this.handleTimeout(event.target.value)}>
                                    {this.timeouts.map((row, index) => (
                                        <option key={row.timeout} value={row.timeout} >{row.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className={styles.StepFormLabel}>Priority <Tooltip title='Tasks are ordered in descending order of priority (highest priority first) and then by ascending order of time (oldest first).' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                                <input type="number" className={`${styles.FormControl}  ${styles.FullWidth}`} min="0" max="50" value={this.state.target.Priority} onChange={event => this.handlePriority(parseInt(event.target.value))} />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <SkipTimeoutExpressionComponent config={this.state.skipTimeoutConfig} defaultExpression={this.state.skipTimeoutDefaultData} expression_json={this.state.target.SkipTimeoutJson} editShow={this.state.showSkipTimeout} toggleHandle={() => this.toggleSkipTimeout()} saveHandle={(expressionJson) => this.handleSkipTimeout(expressionJson)} />
                <StepExpresionComponent config={this.state.stepExpressionConfig} defaultExpression={this.state.stepExpressionDefaultData} expression_json={this.state.target.ExpressionJson} editShow={this.state.showExpression} toggleHandle={() => this.toggleExpression()} saveHandle={(expressionJson) => this.handleExpression(expressionJson)} />
                <OrderingExpressionComponent expression_json={this.state.target.OrderingJson} editShow={this.state.showOrdering} toggleHandle={() => this.toggleOrdering()} saveHandle={(expressionJson) => this.handleOrdering(expressionJson)} />
                
            </div>
        )
    }

}

