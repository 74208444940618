import React, { useEffect, useState } from "react"
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css'
import styles from '../AgentSkillsManagement/styles.module.css'
import PhonesList from "./PhonesList"
import ControlPanel from "./ControlPanel"
import { WhiteListActions } from "../../actions/WhiteListActions"
import { Phone } from "../../models/Whitelist/Phone"
import { Pages } from "../../models/Pages"
import { Snackbar, Backdrop, CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import AddToWhitelist from "./AddToWhitelist"
import RemoveFromWhitelist from "./RemoveFromWhitelist"

const Whitelist = () => {

    const [search, setSearch] = useState('')
    const [backdrop, setBackdrop] = useState(true)
    const [pagination, setPagination] = useState<Pages | null>(null)
    const [errorAlert, setErrorAlert] = useState(false)
    const [errors, setErrors] = useState('')
    const [phones, setPhones] = useState<Phone[]>([])
    const [showAddForm, setShowAddForm] = useState(false)
    const [showRemoveForm, setShowRemoveForm] = useState(false)
    const [loading, setLoading] = useState('loaded')
    const [currPage, setCurrPage] = useState(1)
    const [phoneToRemove, setPhoneToRemove] = useState<Phone | null>(null)
    const [phoneToUpdate, setPhoneToUpdate] = useState<Phone | null>(null)

    useEffect(() => {
        getWhitelist()
    }, [])

    const getWhitelist = () => {
        setLoading('loading')
        WhiteListActions.getWhitelist(currPage, search)
            .then((data: any) => {
                setBackdrop(false)
                setPhones(data.data.data)
                let pages = new Pages(data.data.per_page, data.data.current_page, data.data.last_page)
                setPagination(pages)
                setLoading('loaded')
            }).catch((error) => {
                setErrorAlert(true)
                setErrors(error.message)
                setBackdrop(false)
                setLoading('loaded')
            })
    }

    const handleChangePage = (page: number) => {
        setCurrPage(page)
        getWhitelist()
    }

    var timeoutID: number | null = null
    const handleChangeSearch = (search: string) => {
        setSearch(search)
        if (timeoutID) {
            clearTimeout(timeoutID);
        }
        timeoutID = window.setTimeout(() => {
            getWhitelist()
        }, 1500)
    }

    const handleSave = (phone: Phone) => {
        if (phone.id !== undefined) {
            WhiteListActions.updateWhitelist(phone.id, phone.comment)
                .then(() => getWhitelist())
                .catch((error) => {
                    setErrorAlert(true)
                    setErrors(error.message)
                })
        } else {
            WhiteListActions.createWhitelist(phone.phone, phone.comment)
                .then(() => getWhitelist())
                .catch((error) => {
                    setErrorAlert(true)
                    setErrors(error.message)
                })
        }
    }

    const handleShowRemoveForm = (phone: Phone) => {
        setPhoneToRemove(phone)
        setShowRemoveForm(true)
    }

    const handleShowUpdateForm = (phone: Phone) => {
        setPhoneToUpdate(phone)
        setShowAddForm(true)
    }

    const handleDelete = (phone: Phone) => {
        WhiteListActions.deleteWhitelist(phone.id!, phone.comment)
           .then(() => getWhitelist())
           .catch((error) => {
                setErrorAlert(true)
                setErrors(error.message)
            })
    }
    return (
        <>
            <ControlPanel
                handleAdd={() => { setShowAddForm(true) }}
                search={search}
                searchStatus={loading}
                handleChangeSearch={handleChangeSearch} />
            <PhonesList phones={phones} pagination={pagination} 
            handleChangePage={handleChangePage} 
            handleDelete={handleShowRemoveForm} 
            handleUpdate={handleShowUpdateForm} />
            <AddToWhitelist show={showAddForm} toggleHandle={() => setShowAddForm(!showAddForm)} whitelistPhone={phoneToUpdate} handleSave={handleSave} />
            <RemoveFromWhitelist show={showRemoveForm} toggleHandle={() => setShowRemoveForm(!showRemoveForm)} whitelistPhone={phoneToRemove} handleDelete={handleDelete} />
            <Snackbar open={errorAlert} autoHideDuration={5000} onClose={() => setErrorAlert(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Alert onClose={() => setErrorAlert(false)} severity="error" variant="filled">
                    Error<br />
                    <div className={styles.AlertError}>{errors}</div>
                </Alert>
            </Snackbar>
            <Backdrop className={styles.Backdrop} open={backdrop}>
                <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
                <p className={styles.BackdropProgress}>Loading...</p>
            </Backdrop>
        </>
    )
}

export default Whitelist